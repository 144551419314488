import Layout from '../components/Layout';
import Container from '../components/Container';

import { css } from '@emotion/react';
import { commonStyles, spacing } from '../config/styles';

const styles = {
  titles: css`
    ${commonStyles.h3}
    margin-bottom: ${spacing['20']};
  `,
};

const IndexPage = () => {
  return (
    <Layout>
      <Container>
        <h3 css={styles.titles}>Nerasta</h3>
      </Container>
    </Layout>
  );
};

export default IndexPage;
